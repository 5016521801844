import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TranslatedString } from '@api/endpoints/types'
import { Clear } from '@mui/icons-material'
import { Box } from '@mui/system'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { DictionaryForm } from '@controls/DictionaryForm/DictionaryForm'
import { Table, TableColumn } from '@controls/Table'

export type DictionaryItem<ID> = TranslatedString & {
  id: ID
  createdAt: Date
}

export type SubmitValue = {
  title: TranslatedString
}

export type Props<ID> = {
  title: string
  loading: boolean
  tableData: ReadonlyArray<DictionaryItem<ID>>
  onDelete: React.Dispatch<ID>
  onInsert: React.Dispatch<SubmitValue>
  onUpdate: React.Dispatch<{ id: ID } & SubmitValue>
}

export const Dictionary = <ID,>({
  title,
  loading,
  tableData,
  onDelete,
  onInsert,
  onUpdate
}: Props<ID>): React.ReactElement => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = React.useState<DictionaryItem<ID> | undefined>(undefined)

  const onDeleteEnergyClass =
    (id: ID) =>
    (confirmResult: boolean): void => {
      if (confirmResult && !loading) {
        onDelete(id)
        setSelectedItem(undefined)
      }
    }

  const tableColumns: ReadonlyArray<TableColumn<DictionaryItem<ID>>> = [
    { key: 'eng', title: `${title} (${t('app.languages.en')})`, sortable: true },
    { key: 'est', title: `${title} (${t('app.languages.et')})`, sortable: true },
    { key: 'rus', title: `${title} (${t('app.languages.ru')})`, sortable: true },
    {
      key: null,
      title: null,
      render: (row) => (
        <Box display="flex" columnGap={1}>
          <ButtonWithConfirmation
            color="error"
            size="small"
            disabled={loading}
            onConfirmResult={onDeleteEnergyClass(row.id)}
            confirmTitle={t('global.consent.delete.title')}
            confirmMessage={t('global.consent.delete.message')}
          >
            <Clear />
          </ButtonWithConfirmation>
        </Box>
      )
    }
  ]

  const onSubmit = (data: TranslatedString): void => {
    selectedItem ? onUpdate({ id: selectedItem.id, title: data }) : onInsert({ title: data })
    setSelectedItem(undefined)
  }

  return (
    <>
      <DictionaryForm
        key={tableData.length}
        title={title}
        defaultValues={selectedItem}
        isLoading={false}
        onSubmit={onSubmit}
        onCancel={() => setSelectedItem(undefined)}
      />
      <Table
        columns={tableColumns}
        data={tableData}
        initialSortConfig={{ column: 'createdAt', direction: 'DESC' }}
        onRowClick={setSelectedItem}
      />
    </>
  )
}
