import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { ShortDescription, TranslatedString } from '@api/endpoints/types'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { FormTextFieldStyled, useFormZod } from '@controls/Form'

const FormValues = TranslatedString(ShortDescription)
type FormValues = z.infer<typeof FormValues>

export type Props = Readonly<{
  title: string
  defaultValues?: FormValues
  isLoading: boolean
  onSubmit: React.Dispatch<FormValues>
  onCancel: React.DispatchWithoutAction
}>

export const DictionaryForm: React.FC<Props> = ({ title, defaultValues, isLoading, onSubmit, onCancel }) => {
  const { t } = useTranslation()

  const form = useFormZod({
    schema: FormValues,
    defaultValues
  })

  React.useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues, form])

  const onSubmitInternal: React.Dispatch<FormValues> = (values) => {
    onSubmit(values)
    form.reset({})
  }

  const onCancelInternal: React.DispatchWithoutAction = () => {
    onCancel()
    form.reset({})
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmitInternal)}>
      <Box display="flex" flexDirection="column" rowGap={2} mb={4}>
        <FormTextFieldStyled
          form={form}
          name="eng"
          label={`${title} (${t('app.languages.en')})`}
          disabled={isLoading}
        />
        <FormTextFieldStyled
          form={form}
          name="est"
          label={`${title} (${t('app.languages.et')})`}
          disabled={isLoading}
        />
        <FormTextFieldStyled
          form={form}
          name="rus"
          label={`${title} (${t('app.languages.ru')})`}
          disabled={isLoading}
        />
        <Box display="flex" columnGap={2} mt={2}>
          <Button type="submit" disabled={isLoading}>
            {t('global.buttons.submit')}
          </Button>
          {defaultValues && (
            <Button type="reset" disabled={isLoading} onClick={onCancelInternal}>
              {t('global.buttons.cancel')}
            </Button>
          )}
        </Box>
      </Box>
    </form>
  )
}
